import { useContext, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { Alert } from '../../components';
import UserContext from '../../context/UserContext';
import { api } from '../../services/api';
import {
  ButtonSubmit,
  Divider,
  FlexItem,
  HorizontalDiv,
  Input,
  LinkComponent,
  LinkItem,
  LogoWhite,
  Text,
  Title
} from '../../styles';
import { colors, weight } from '../../theme';
import { Background, BackgroundCover } from './styles';

const LoginPage = ({ title }) => {
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState();
  const [alertMessage, setAlertMessage] = useState('');
  const { state, setState } = useContext(UserContext);

  document.title = `Portal do Proprietário | ${title}`;

  const handleSubmit = (e) => {
    const formData = new FormData(e.currentTarget);
    e.preventDefault();
    const obj = {};
    for (let [key, value] of formData.entries()) {
      obj[key] = value;
    }
    const { login, password } = obj;

    api
      .post('/login', {
        login: login,
        senha: password
      })
      .then((res) => {
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('name', res.data.nome);
        localStorage.setItem('email', res.data.email);
        localStorage.setItem('just_logged', true);
        localStorage.setItem('is_admin', res.data.flg_admin);
        const { novaSenha } = res.data;
        localStorage.setItem('novaSenha', novaSenha);
        setState({ ...state, flg_admin: res.data.flg_admin });
        setAlertType('success');
        setAlertOpen(true);

        if (novaSenha) {
          navigate('/change-password');
          setAlertMessage('Login efetuado com sucesso. Agora mude sua senha!');
        } else {
          setAlertMessage('Login efetuado com sucesso');
          navigate('/');
        }

        setTimeout(() => {
          setAlertOpen(false);
        }, 3000);
      })
      .catch(() => {
        e.preventDefault();
        setAlertType('error');
        setAlertMessage('Login ou senha incorretos. Tente novamente');
        setAlertOpen(true);
        setTimeout(() => {
          setAlertOpen(false);
        }, 3000);
      });
  };

  return (
          <>
            {isMobile ? 
                <Background>
                  {alertOpen && <Alert message={alertMessage} type={alertType} />}
                    <BackgroundCover>
                      <FlexItem mWidth='100vw' height='100%' width='503px' FDirection='column' bFlex='center' flex>
                          <LogoWhite width={isMobile ? '165px' : '220px'} height={isMobile ? '95px' : '125px'} margin='auto' />
                          <Title color={colors.white} weight={weight.regular}>
                              Portal do proprietário
                          </Title>
                      </FlexItem>
                    </BackgroundCover>
                  <FlexItem>
                    <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '40vh', justifyContent: 'center', margin: 'auto' }}>
                      <FlexItem mWidth='none'>
                        <Text color={colors.darkBlue} weight={weight.bold}>
                          Login
                        </Text>
                        <Input marginTop='8px' mWidth='250px' name='login' isFlex />
                        <Text color={colors.darkBlue} weight={weight.bold}>
                          Senha
                        </Text>
                        <Input marginTop='8px' mWidth='250px' name='password' type='password' isFlex />
                      </FlexItem>
                      <FlexItem>
                        <ButtonSubmit bgColor='darkBlue' mTop='10px' height='30px' padding="0px 16px"  type='submit'>Entrar</ButtonSubmit>
                        <HorizontalDiv width='100%' alignItems='flex-end' gap='16px'>
                          <LinkComponent decoration='none' to='/first-access'>
                            <LinkItem font='12px' color='darkBlue' onClick={() => navigate('/first-access')}>
                                Primeiro acesso
                            </LinkItem>
                          </LinkComponent>
                          <Divider color='darkBlue' height='16px' borderSize='1.75px'/>
                          <LinkComponent decoration='none' to='/forgot-password'>
                            <LinkItem font='12px' color='darkBlue' onClick={() => navigate('/forgot-password')}>
                                Esqueci minha senha
                            </LinkItem>
                          </LinkComponent>
                        </HorizontalDiv>
                      </FlexItem>
                    </form>
                  </FlexItem>
                </Background>

            :       
    
            <BackgroundCover>
              {alertOpen && <Alert message={alertMessage} type={alertType} />}
              <Background>
                <FlexItem mWidth='100%' width='503px' FDirection='column' bFlex='center' flex>
                  <LogoWhite width={'220px'} height={'125px'} margin='auto' />
                  <Title color={colors.white} mb={'0px'} weight={weight.regular}>
                      Portal do proprietário
                  </Title>
                </FlexItem>
                <FlexItem style={{height: '100%'}}>
                  <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '90%', margin: 'auto', justifyContent: 'space-between'}}>
                    <FlexItem>
                      <Text color={colors.darkBlue} weight={weight.bold}>
                        Login
                      </Text>
                      <Input marginTop='8px' mWidth='300px' name='login' isFlex />
                      <Text color={colors.darkBlue} weight={weight.bold}>
                        Senha
                      </Text>
                      <Input marginTop='8px' mWidth='300px' name='password' type='password' isFlex />
                    </FlexItem>
                    <FlexItem >
                      <ButtonSubmit  bgColor='darkBlue' type='submit'>Entrar</ButtonSubmit>
                      <HorizontalDiv alignItems='flex-end' gap='16px'>
                        <LinkComponent decoration='none' to='/first-access'>
                          <LinkItem color='white' onClick={() => navigate('/first-access')}>
                              Primeiro acesso
                          </LinkItem>
                        </LinkComponent>
                        <Divider color='white' height='16px' borderSize='1.75px'/>
                        <LinkComponent decoration='none' to='/forgot-password'>
                          <LinkItem color='white' onClick={() => navigate('/forgot-password')}>
                              Esqueci minha senha
                          </LinkItem>
                        </LinkComponent>
                      </HorizontalDiv>
                    </FlexItem>
                  </form>
                </FlexItem>
              </Background>
            </BackgroundCover>}

        </>
  );
};

export default LoginPage;
