import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import LogoYellowBlueImg from './assets/Amarelo_Azul.png';
import iconPdfImage from './assets/icon_pdf.svg';
import iconCostaoLogin from './assets/iconCostaoLogin.png';
import { colors, fontSize, weight } from './theme';

const GlobalStyle = createGlobalStyle`
    html {
      margin: 0!important;
      padding: 0!important;
      -webkit-print-color-adjust: exact;
    }
    body {
      @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700&display=swap');
      margin: 0!important;
      padding: 0!important;
      font-family: 'Montserrat', sans-serif;
    }
  `;

const Aligner = styled.div`
  text-align: ${(props) => props.direction};
  width: 100%;
  display: ${(props) => (props.flex ? 'flex' : '')};
  justify-content: ${(props) => (props.flex ? 'center' : '')};
  align-items: ${(props) => (props.flex ? 'center' : '')};
  @media (max-width: 500px) {
    display: ${({ mDisplayN }) => mDisplayN && 'none'};
  }
`;

const ButtonSubmit = styled.button`
  background-color: ${({bgColor}) => bgColor ? colors[bgColor] : bgColor};
  color: ${colors.white};
  height: ${(props) => props.height || '48px'};
  padding: ${({ padding }) => padding || '14px 32px'};
  border: none;
  font-size: ${({fSize}) => fSize ? fontSize[fSize] : fSize};
  align-content: center;
  margin-top: ${(props) => props.mTop || '30px'};
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    opacity: 0.8;
  }
`;

const Divider = styled.div`
  border-right: ${(props) => props.borderSize || '6px'} solid ${({color}) => color || colors.color};
  height: ${(props) => props.height || '447px'};
`;

const DividerHorizontal = styled.div`
  border-bottom: 1px solid ${colors.gray};
  width: ${(props) => props.width || '238px'};
  margin: auto;
  padding: ${(props) => props.padding};
`;

const FlexItem = styled.div`
  text-align: ${({ tAlign }) => tAlign};
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  height: ${(props) => props.height};
  padding: ${(props) => props.padding ? props.padding : '0'};
  min-height: ${({ minHeight }) => minHeight};
  justify-content: ${({ jFlex }) => jFlex && 'center'};
  align-items: ${({ bFlex }) => bFlex || 'center'};
  display: ${(props) => (props.flex ? 'flex' : 'block')};
  cursor: ${({ hasCursor }) => hasCursor && 'pointer'};
  flex-direction: ${(props) => (props.FDirection ? props.FDirection : 'row')};
  gap: ${({ gap }) => gap};
  @media (max-width: 500px) {
    width: ${({ mWidth }) => mWidth || '95%'};
    margin: ${({ mMargin }) => mMargin};
    padding: ${({ mPadding }) => mPadding};
    height: ${(props)=> props.mHeight || props.height};
  }
`;

const PdfIcon = styled.div`
  display: flex;
  width: ${(props) => props.width || '20px'};
  height: ${(props) => props.height || '20px'};
  background-image: url(${iconPdfImage});
  background-size: contain;
  margin-left: 10px;
`;

const Input = styled.input`
  border: none;
  box-shadow: 0 8px 0 ${(props) => 'lightgray'};
  border-radius: 8px;
  width: ${({ width }) => width || '400px'};
  color: ${(props) => props.color || colors.primary};
  font-size: ${fontSize.label};
  height: ${(props) => props.height || '46px'};
  display: ${(props) => (props.isFlex ? 'flex' : '')};
  margin-top: ${({marginTop})=> marginTop};
  &:focus {
    outline: none;
  } 
  @media (max-width: 578px) {
    width: ${({ mWidth }) => mWidth || '200px'};
    height: ${({ mHeight }) => mHeight || '30px'};
    font-size: ${fontSize.text};
  }
  ${({ type }) =>
    type === 'month' &&
    `
      &::-webkit-calendar-picker-indicator {
        background: transparent;
        color: blue;
        cursor: pointer;
        position: absolute;
        height: 46px;
        width: 250px;
      }
    `}
`;

const LinkComponent = styled(Link)`
  color: ${({ color }) => color || colors.color};
  cursor: ${({ noCursor }) => noCursor || 'pointer'};
  display: block;
  font-weight: ${({ w }) => w || weight.bold};
  padding: ${(props) => props.p};
  text-decoration: ${(props) => props.decoration};
  &:visited {
    text-decoration: ${(props) => props.decoration};
    color: ${({ color }) => color || colors.color};
  }
  &:hover {
    text-decoration: underline;
  }
`;

const LinkItem = styled.span`
  color: ${(props) => props.color || colors.darkBlue};
  display: ${(props) => (props.flex ? 'flex' : 'block')};
  padding-top: ${(props) => props.pt || '15px'};
  text-decoration: ${(props) => props.textDecor || 'none'};
  font-family: 'Montserrat', sans-serif;
  font-size: ${({font}) => font || fontSize.font};
  font-weight: ${({ w }) => w || weight.regular};
  text-align: ${({ align }) => align || 'right'};
  cursor: ${(props) => (props.hasCursor ? 'pointer' : '')};
  @media (max-width: 578px) {
    padding-left: ${({ mPl }) => mPl};
  }
`;

const LogoWhite = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: transparent url(${iconCostaoLogin}) no-repeat;
  background-size: contain;
  background-position: center;
  margin: ${(props) => props.margin};
  justify-content: 'center';
  cursor: ${({ hasCursor }) => hasCursor && 'pointer'};
`;

const LogoYellowBlue = styled.div`
  height: 130px;
  width: 100%;
  background: transparent url(${LogoYellowBlueImg}) no-repeat;
  background-size: contain;
  background-position: center;
  margin: ${(props) => props.margin};
  justify-content: 'center';
  cursor: pointer;
`;

const SectionTitle = styled.h1`
  color: ${(props) => props.color};
  font-size: ${isMobile ? fontSize.label : fontSize.titleSection};
  font-weight: ${(props) => props.weight};
  margin-top: ${({ mt }) => mt};
  text-align: ${(props) => props.align};
`;

const Table = styled.table`
  width: 100%;
  background-color: transparent;
  border-spacing: 0 1em;
`;

const TableBody = styled.tbody`
  background: transparent;
  text-align: left;
  display: flex;
  padding: ${({ padding }) => padding};
  height: 100%;
  flex-direction: column;
`;

const TableContainer = styled.div`
  overflow-x: auto;
`;

const TableBodyItem = styled.td`
  color: ${(props) => colors[props.color] || props.color || colors.darkGray};
  width: ${(props) => props.width};

  padding-bottom: ${(props) => props.border && '5px'};
  padding-right: ${({ pr }) => pr};
  font-weight: ${({ weight }) => weight};
  padding-left: ${({pl}) => pl};
  padding: 0px 10px 0px 10px;
`;

const TableHeader = styled.thead`
  text-align: left;
  display: block;
`;

const TableHeaderItem = styled.th`
  width: ${(props) => props.width};
  color: ${(props) => colors[props.color] || props.color || colors.darkGray};
  font-weight: ${weight.bold};
  padding-right: ${({ pr }) => pr};
  padding: 0px 10px 0px 10px;
  @media (max-width: 500px) {
    width: ${({ mWidth }) => mWidth};
  }
`;

const TableRow = styled.tr`
  border-bottom: ${(props) => props.border ? `1px solid ${colors.gray}` : 'none'};
  @media (max-width: 500px) {
    width: 100%;
    font-size: 12px;
    margin-top: 0;
  }
`;

const Text = styled.label`
  color: ${(props) => props.color || colors.darkBlue};
  font-size: ${(props) => props.size || fontSize.text};
  font-weight: ${(props) => props.weight};
  display: ${(props) => (props.inline ? 'inline' : 'block')};
  text-align: ${(props) => props.textAlign || 'left'};
  margin-top: ${({ mt }) => mt || '20px'};
  margin-bottom: ${(props) => props.mb};
  padding: ${(props) => props.padding};
    cursor: ${({ hasCursor }) => hasCursor && 'pointer'};
  @media (max-width: 500px) {
    padding: ${({ mP }) => mP};
    font-size: ${(props) => props.mSize};
  }
`;

const TextItem = styled.h5`
  color: ${({ color }) => color || colors.darkBlue};
  font-weight: ${weight.medium};
`;

const Title = styled.h1`
  color: ${(props) => props.color ? colors[props.color] : props.color};
  font-size: ${(props) => props.fontSize || fontSize.title};
  margin-bottom: ${(props) => props.mb || '64px'};
  font-weight: ${(props) => props.weight};
  text-align: ${(props) => props.align};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: ${(props) => props.display};
  justify-content: ${(props) => props.justify};
  text-shadow: ${(props) => props.shadow && `1px 1px 2px ${colors.secondary}`};
  @media (max-width: 500px) {
    font-size: ${(props) => props.fontSize || fontSize.label};
    text-align: center;
  }
`;

const HorizontalDiv = styled.div`
    display:flex;
    width: ${(props) => props.width};
    align-items: ${(props) => props.alignItems};;
    gap: ${(props) => props.gap || '0px'};
    justify-content: center;
`;

export {
  Aligner,
  ButtonSubmit,
  Divider,
  DividerHorizontal,
  FlexItem, GlobalStyle, HorizontalDiv, Input,
  LinkComponent,
  LinkItem,
  LogoWhite,
  LogoYellowBlue,
  PdfIcon,
  SectionTitle,
  Table,
  TableBody,
  TableBodyItem,
  TableContainer,
  TableHeader,
  TableHeaderItem,
  TableRow,
  Text,
  TextItem,
  Title
};

