import BlockIcon from '@mui/icons-material/Block';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useContext, useEffect } from "react";
import { isMobile } from 'react-device-detect';
import { useNavigate } from "react-router";
import UserContext from "../../context/UserContext";
import { ImgRender } from '../../pages/MidLevelPage/styles';
import { Text } from '../../styles';
import { colors, fontSize, weight } from '../../theme';
import billImg from './../../assets/bill.svg';
import newsIcon from './../../assets/news-icon.svg';

const Administration = () => {
    const isAdmin = (localStorage.getItem("is_admin") === 'true');
    const name = localStorage.getItem('name');
    const navigate = useNavigate();

    const listIcons = [
        // {
        //     key: "img-docs",
        //     title: "Documentos",
        //     alt: "Documentos",
        //     route: null,
        //     src: docImage
        // },
        {
            key: "img-boletos",
            title: "Boletos",
            alt: "Boletos",
            route: "lista-arquivos-boletos",
            src: billImg
        },
        {
            key: "img-avisos",
            title: "Avisos",
            alt: "Avisos",
            route: "lista-avisos",
            src: newsIcon
        },
        {
            key: "img-relatorio",
            title: "Relatório de proprietários",
            alt: "Relatório de proprietários",
            route: "relatorio-proprietarios",
            icon: <span title="Relatório de proprietários">
                    <ListAltIcon style={{ color: "rgb(19, 57, 92)", fontSize: isMobile ? "70px" : "100px", cursor: "pointer"}} onClick={() => clickLink('relatorio-proprietarios')}/>
                  </span>
        }
    ];

    const { state, setState } = useContext(UserContext);

    useEffect(() => {
        setState({
          ...state,
          breadcrumb: [
            {
              text: "Home", link: '/'
            },{
              text: 'Área Administrativa'
            }
          ],
        });
      // eslint-disable-next-line
    }, []);

    const clickLink = (route) => {
        if(route !== null){
            navigate(route);
        }
    }

    const AdminIcons = () => {
        return (
            <div style={{marginTop: '50px'}}>
                <Text
                    size={fontSize.text}
                    weight={weight.bold}
                    inline
                    color={colors.primary}
                    mP='0 10px'>
                    Seja bem vindo(a),{' '}
                </Text>
                <Text
                    size={fontSize.text}
                    weight={weight.bold}
                    inline
                    color={colors.secondary}>
                    {name}.
                </Text>
    
                <Grid container spacing={2} style={{marginTop: '50px'}}>
                    {listIcons.map((item, index) => (
                        <Grid item xs={4} style={{textAlign: 'center'}} key={index}>
                            {item.src ? 
                                <ImgRender
                                    mHeight='70px'
                                    {...item}
                                    onClick={() => clickLink(item.route)}/>:
                                item.icon
                            }
                            <Text textAlign='center' color={colors.primary} size={isMobile && '16px'}>
                                {item.title}
                            </Text>
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    }

    return (
        <>
            {isAdmin ? <AdminIcons /> : 
                <Grid container spacing={2} style={{marginTop: '50px'}}
                justifyContent="center"
                alignItems="center">
                    <Grid item xs={1}>
                        <BlockIcon style={{color: 'red', fontSize: '50px'}}/>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h4" gutterBottom>
                            Acesso não autorizado
                        </Typography>
                    </Grid>
                </Grid>
            }
        </>
    );
}

export default Administration