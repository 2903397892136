import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";
import { FlexItem, Text } from "../../styles";
import { colors } from "../../theme";
import { ImgRender, ItemsCard, ItemText } from "./styles";

function MidLevelPage({ backLink, items, text }) {
  const navigate = useNavigate();
  const { state, setState } = useContext(UserContext);

  useEffect(() => {
    if (backLink) {
      setState({
        ...state,
        breadcrumb: [
          {
            text: "Home",
            link: "/",
          },
          { text: text },
        ],
      });
    }
    // eslint-disable-next-line
  }, [items, backLink, text]);

  return (
    <>
      <FlexItem
        flex
        width="100%"
        height="100%"
        minHeight="300px"
        jFlex
        bFlex='none'
        mWidth="100%"
        FDirection="column"
      >
        {items.map((item, index) => (
          <FlexItem flex mWidth="100%" key={index} padding="3em 0 2em 0" jFlex tAlign="center">
            <ItemsCard onClick={() => navigate(item.link)} hasCursor style={{boxShadow: '0px 2px 4px 0px #00000040', borderRadius: '13px'}}>
              <ItemText width='20%' bgColor="darkBlue" style={{ borderRadius: '13px 0px 0 13px', padding: '0.5em 0.5em'}}>
                <ImgRender
                  width='100px'
                  height='55px'
                  mHeight='35px'
                  key={item.link}
                  alt={item.link}
                  src={item.src}
                  />
              </ItemText>

              <ItemText bgColor='white'>
                <Text mSize='16px' mt='0' textAlign="center" size='20px' weight={'500'} mHeight='35px' color={colors.darkBlue}>
                  {item.text}
                </Text>
              </ItemText>
            </ItemsCard>
          </FlexItem>
        ))}
      </FlexItem>
    </>
  );
}

export default MidLevelPage;
