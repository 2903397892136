import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { FlexItem, LinkComponent } from "../../styles";
import { colors } from "../../theme";
import { Chevron } from "./styles";

function Breadcrumb({ items }) {
  const navigate = useNavigate();

  if (!items) return null;
  return (
    <FlexItem flex padding={isMobile ? '0 0' : '0 10vw'}>
      {items.map((item, index) => (
        <div key={`breadcrumb-${index}`}>
          <LinkComponent
            decoration={item.link ? "underline" : "none"}
            noCursor={!item.link}
            to={item.link}
            w="500"
            color={colors.darkBlue}
            p="0 .7em"
          >
            {item.text}
          </LinkComponent>
          <Chevron />
        </div>
      ))}
    </FlexItem>
  );
}

export default Breadcrumb;
