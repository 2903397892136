import styled from "styled-components";
import FamiliaImage from "../../assets/FamiliaImageCompleto.png";

const Background = styled.main`
  height: 100%;
  display: flex;
  align-items: center;
  width: 100vw;
  text-align: center;
  flex-direction: column;
  @media (max-width: 500px) {
    background-color: #EFF0F6;
    flex-direction: column;
    position: relative;
  }
`;

const BackgroundCover = styled.div`
  background: url(${FamiliaImage}) no-repeat center center;
  background-size: cover;
  height: 100%;

  @media (max-width: 500px) {
    padding-right: 0;
    border-radius: 0 0 10px 10px;
  }
  @media (min-width: 1280px) {
    display: flex;
    justify-content: center;
  }
  
  `;
  
export { Background, BackgroundCover };
