import { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router";
import { Alert } from "../../components";
import UserContext from "../../context/UserContext";
import { api } from "../../services/api";
import {
  Aligner,
  ButtonSubmit,
  FlexItem,
  Input,
  SectionTitle,
  Text,
} from "../../styles";
import { colors, weight } from "../../theme";

const PasswordReset = () => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState();
  const [alertMessage, setAlertMessage] = useState("");
  const token = localStorage.getItem("token");
  const navigate = useNavigate()

  const { state, setState } = useContext(UserContext);

  useEffect(() => {
      setState({
        ...state,
        breadcrumb: [
          {
            text: "Home", link: '/'
          },{
            text: 'Redefinição de senha'
          }
        ],
      });
    // eslint-disable-next-line
  }, []);

  const handleSubmit = (e) => {
    const formData = new FormData(e.currentTarget);
    e.preventDefault();
    const obj = {};
    for (let [key, value] of formData.entries()) {
      obj[key] = value;
    }

    const { currentPassword, newPassword, confirmNewPassword } = obj;

    api
      .put(
        "/senha",
        {
          senhaAtual: currentPassword,
          senhaNova: newPassword,
          senhaConfirmacao: confirmNewPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setAlertType("success");
        setAlertMessage("Senha alterada com sucesso!");
        setAlertOpen(true);
        setTimeout(() => {
          setAlertOpen(false);
          localStorage.removeItem("novaSenha");
          navigate('/')
        }, 3000);
      })
      .catch((error) => {
        setAlertType("error");
        setAlertMessage(`Erro! ${error}`);
        setAlertOpen(true);
        setTimeout(() => {
          setAlertOpen(false);
        }, 3000);
      });
  };

  return (
    <>
      {alertOpen && <Alert message={alertMessage} type={alertType} />}
      <FlexItem margin="auto" isFlex width="400px">
        <form onSubmit={handleSubmit} style={{display: isMobile ? 'flex' : 'block', flexDirection: 'column', alignItems: 'center'}}>
          <SectionTitle
            color={colors.primary}
            align="left"
            weight={weight.bold}
          >
            Redefinição de senha
          </SectionTitle>
          <Text color={colors.primary} weight={weight.medium}>
            Senha atual
          </Text>
          <Input
            mWidth="300px"
            height="40px"
            name="currentPassword"
            type="password"
            isFlex
          />
          <Text color={colors.primary} weight={weight.medium}>
            Senha nova
          </Text>
          <Input
            mWidth="300px"
            height="40px"
            name="newPassword"
            type="password"
            isFlex
          />
          <Text color={colors.primary} weight={weight.medium}>
            Confirme a nova senha
          </Text>
          <Input
            mWidth="300px"
            height="40px"
            name="confirmNewPassword"
            type="password"
            isFlex
          />
          <Aligner direction="center">
            <ButtonSubmit type="submit" bgColor='secondary' fSize='text'>Enviar</ButtonSubmit>
          </Aligner>
        </form>
      </FlexItem>
    </>
  );
};

export default PasswordReset;
