import { Grid, Link } from '@mui/material';
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { ButtonSubmit, FlexItem, HorizontalDiv, LogoWhite } from "../../styles";
import ProfileMenu from "./ProfileMenu";
import { HeaderStyle, HeaderTitle, ListIcon } from "./styles";

const Header = ({ logged, onMenuClick }) => {
  const navigate = useNavigate();

  return (
    <>
      <HeaderStyle>
        <FlexItem flex bFlex='center' height="100%" width="100%" margin="auto">
          {logged && <ListIcon onClick={() => onMenuClick()} />}
            <LogoWhite
              onClick={() => navigate("/")}
              margin="auto"
              width="180px"
              height="100px"
              hasCursor
            />
          {logged && <ProfileMenu />}
        </FlexItem>

      </HeaderStyle>
      <HorizontalDiv>
          <FlexItem style={{marginTop: '20px'}} flex height="100%" width="100%">
          <Grid container justifyContent="flex-end" alignItems='center' style={{gap: isMobile && "15px", paddingBottom: "20px"}}>
              <Grid item xl={8} md={12} xs={12}>
                <div className="title-header">
                  <HeaderTitle color="darkBlue">Portal do Proprietário</HeaderTitle>
                </div>
              </Grid>
              <Grid item xl={2} md={4} xs={4}>
                <ButtonSubmit onClick={() => navigate("/atualizacao-cadastral")} mTop='0px' bgColor='secondary' height='35px' padding='0px 16px' fSize={isMobile ? '12px' : 'text'} >
                  <Link style={{cursor: "pointer", color: "white", textDecoration: 'none'}}>Atualize seu cadastro!</Link>
                </ButtonSubmit>
              </Grid>
          </Grid>
        </FlexItem>
      </HorizontalDiv>
    </>
  );
};

export default Header;
