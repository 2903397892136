import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Alert } from '../../components';
import { api } from '../../services/api';
import {
    Aligner,
    ButtonSubmit,
    FlexItem,
    Input,
    LinkItem,
    SectionTitle,
    Text
} from '../../styles';
import { colors, weight } from '../../theme';

const FirstAccess = () => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState();
  const [alertMessage, setAlertMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    const formData = new FormData(e.currentTarget);
    e.preventDefault();
    const obj = {};
    for (let [key, value] of formData.entries()) {
      obj[key] = value;
    }

    const { email } = obj;

    api
      .post('/senha', {
        email: email
      })
      .then((res) => {
        setAlertType('success');
        setAlertMessage('A nova senha chegará em seu email em alguns minutos!');
        setAlertOpen(true);
        setTimeout(() => {
          setAlertOpen(false);
          navigate('/login');
        }, 3000);
      })
      .catch((error) => {
        setAlertType('error');
        setAlertMessage(`Erro! ${error.message}`);
        setAlertOpen(true);
        setTimeout(() => {
          setAlertOpen(false);
        }, 3000);
      });
  };

  return (
    <>
      {alertOpen && <Alert message={alertMessage} type={alertType} />}
      <LinkItem onClick={() => navigate('/login')} hasCursor align='left' textDecor='underline' mPl='20px'>
          Voltar para login
      </LinkItem>
      <FlexItem margin='50px auto 0' flex width='400px' FDirection='column'>
        <form onSubmit={handleSubmit} stcyle={{ margin: 'auto' }}>
          <SectionTitle
            color={colors.primary}
            align='center'
            weight={weight.bold}
          >
            Primeiro Acesso
          </SectionTitle>
          <Text color={colors.primary} weight={weight.medium} mb='10px' mt='40px'>
            Informe seu e-mail
          </Text>
          <Input
            mWidth='300px'
            height='40px'
            name='email'
            type='email'
          />
          <Aligner direction='center'>
            <ButtonSubmit bgColor={'secondary'} type='submit'>Enviar</ButtonSubmit>
          </Aligner>
        </form>
      </FlexItem>
    </>
  );
};

export default FirstAccess;
