import Grid from '@mui/material/Grid';
import { useContext, useEffect, useState } from 'react';
import logo from "../../assets/logo_institucional_azul.png";
import { api } from '../../services/api';
import { colors, weight } from '../../theme';
import './style.css';

import { isMobile } from 'react-device-detect';
import downloadImg from '../../assets/download2.svg';
import { Select } from '../../components';
import UserContext from '../../context/UserContext';
import { downloadStatement } from '../../services/download-statement';
import { downloadFile } from '../../services/downloadfs';
import { getOwnerByUH } from '../../services/owner';
import {
  Aligner,
  FlexItem,
  Input,
  SectionTitle,
  Table,
  TableBody,
  TableBodyItem,
  TableContainer,
  TableHeader,
  TableHeaderItem,
  TableRow,
  Text,
  TextItem
} from '../../styles';
import ModalDetail from './components/ModalDetail';

function Statement() {
  const token = localStorage.getItem('token');
  const [data, setData] = useState(null);
  const [message, setMessage] = useState();
  const [monthYear, setMonthYear] = useState('042022');
  const [uhs, setUhs] = useState(0);
  const [uhSetted, setUhSetted] = useState(null);
  const { state, setState } = useContext(UserContext);
  const [isModalOpen, setModalOpen] = useState(false);
  const [detailModal, setDetailModal] = useState(null);
  const [ownerName, setOwnerName] = useState(localStorage.getItem("name"));
  const isAdmin = (localStorage.getItem("is_admin") === 'true');

  const date = new Date();
  const month = date.getMonth() + 1;
  const formattedMonth = month < 10 ? `0${month}` : month;
  const my = `${date.getFullYear()}-${formattedMonth}`;

  const formattedValue = (value) => {
    const my = value.replace(/[^0-9]/g, '');
    const y = my.slice(0, 4);
    let m = my.slice(4, 6);
    return `${m}${y}`;
  };
  const formatMessage = (message) => {
    let date = message.substring(message.indexOf(`ser`), message.length);
    date = date.slice(4, 7) + ` de` + date.slice(7, date.length);
    const newM = `Dados disponívels para visualização a partir de ${date} - (Data da implantação do sistema)`;
    return newM;
  };

  const handleDownloadDetail = async (range, apto) => {
    const res = window.confirm(
      'Deseja efetuar o download do documento em PDF?'
    );
    if (res) {
      setState({ ...state, loading: true });
      await downloadFile('Extrato_detalhado.pdf', range, apto);
      setTimeout(() => {
        setState({ ...state, loading: false });
      }, 1000);
    }
    setModalOpen(false);
  };
  const fetchDocs = (token, my, uh) => {
    setState({ ...state, loading: true });
    api
      .get(`/uh/${uh}/extrato?mesano=${my}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        setState({ ...state, loading: false });
        if (typeof res.data === 'string') {
          setMessage(res.data);
          setData(null);
        } else {
          setMessage(null);
          setData(res.data);
          setState({ ...state, loading: false });
        }
      })
      .catch(() => {
        setData(null);
        setState({ ...state, loading: false });
      });
  };

  const fetchUhs = (token) => {
    api
      .get('/uh', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        setUhs(res.data);
        setUhSetted(res.data[0].id);
      });
  };

  useEffect(() => {
    fetchUhs(token);
    setMonthYear(formattedValue(my));
  }, [token, my]);

  useEffect(() => {
    fetchDocs(token, monthYear, uhSetted);
  }, [token, monthYear, uhSetted, my]);

  useEffect(() => {
    if (isModalOpen) {
      window.scrollTo(0, 0);
    }
  }, [isModalOpen]);

  useEffect(() => {
    setState({
      ...state,
      breadcrumb: [
        {
          text: "Home", link: '/'
        },        
        { 
          text: 'Pool', link: '/loc'
        },
        { 
          text: 'Extrato de Locação'
        }
      ],
    });
  // eslint-disable-next-line
}, []);


  const isNeg = (value) => value.includes('-');

  const handleInputDateChange = (e) => {
    const { value } = e.target;

    const newV = formattedValue(value);

    if (newV.length === 6) {
      setMonthYear(newV);
    }
  };

  const handleUhChange = (e) => {
    setUhSetted(e.target.value);
    
    if(isAdmin){
      getOwnerByUH(e.target.value).then(({data}) => {
        setOwnerName(data.nome);
      }).catch(() => {
        setOwnerName(localStorage.getItem("name"));
      });
    }
  };

  const handleDownloadMobile = async () => {
    await setModalOpen(true);
    const el = document.getElementById('download-button');
    await el.click();
    setModalOpen(false);
  };

  return (
    <>
      {isModalOpen && (
        <div className='modal-container'>
          <dialog open={isModalOpen}>
            <span
              onClick={() => setModalOpen(false)}
              style={{ position: 'absolute', right: '10px', cursor: 'pointer' }}
            >
              x
            </span>
            <summary
              style={{
                display: 'flex',
                alignContent: 'center',
                width: '100%',
                justifyContent: 'center'
              }}
            >
              <h4>Extrato detalhado</h4>
            </summary>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
                height: '30px',
                marginLeft: '0',
                width: '100%',
                cursor: 'pointer'
              }}
              role='button'
              id='download-button'
              onClick={() => handleDownloadDetail(my, uhSetted)}
            >
              <Text mt='0' size='12px' style={{ cursor: 'pointer' }}>
                Download do extrato
              </Text>
              <img
                title='Download do extrato'
                className='download-icon'
                src={downloadImg}
                alt='download icon'
                style={{ width: '15px', marginLeft: '10px' }}
              />
            </div>
            {detailModal && <ModalDetail data={detailModal} />}
          </dialog>
        </div>
      )}
      <FlexItem margin='0px auto 30px' isFlex width='fit-content'>
        <Aligner direction='center'>
        {isMobile ?
        <SectionTitle color={colors.darkBlue}>Extrato de locação</SectionTitle> :
        
        <Grid container spacing={12}>
          <Grid item md={4} sm={2}>
            <div style={{float: 'left'}}>
              <img src={logo} style={{width: '130px'}} alt=''/>
            </div>
          </Grid>
          <Grid item md={4} sm={2}>
              <h3 className='h3-blue'>Extrato de locação</h3>
          </Grid>
          <Grid item md={4} sm={2}>
            <div style={{float: 'right', color: colors.darkBlue}}>
              <h5>{ownerName}</h5>
            </div>
          </Grid>
        </Grid>
        }

          <FlexItem flex bFlex='end' mPadding='0 .5em'>
            <div style={{ marginRight: '10px'}}>
              <Text color={colors.darkBlue}>Mês/Ano</Text>
              <div style={{ marginLeft: isMobile && 'auto' }}>
                <Input
                  onChange={(e) => handleInputDateChange(e)}
                  placeholder={`Ex: ${my}`}
                  width='250px'
                  color={colors.primary}
                  defaultValue={my}
                  type='month'
                  marginTop='5px'
                  mHeight='40px'
                />
              </div>
            </div>
            <div>
              <Text color={colors.darkBlue}>Apto</Text>
              <div
                style={{
                  marginLeft: isMobile && 'auto',
                  paddingRight: isMobile && '10px'
                }}
              >
                <Select styleProps={{bgColor: 'white', bRadius: '8px', mHeight: '42px'}} onChange={handleUhChange} items={uhs} />
              </div>
            </div>
            <Aligner
              direction='left'
              style={{
                marginTop: isMobile ? '1em'  : '4em',
                marginLeft: !isMobile && '3em',
                alignSelf: 'end',
              }}
            >
              <img
                title='Download do extrato'
                className='download-icon'
                src={downloadImg}
                alt='download icon'
                onClick={() => downloadStatement(data, my, ownerName)}
              />
            </Aligner>
          </FlexItem>
          {message && (
            <SectionTitle
              mt='40px'
              weight={weight.light}
              color={colors.primary}
            >
              {formatMessage(message)}.
            </SectionTitle>
          )}
          {!data && !message ? (
            <SectionTitle
              mt='40px'
              weight={weight.light}
              color={colors.primary}
            >
              Não existem documentos para serem mostrados.
            </SectionTitle>
          ) : (
            !message && (
              <TableContainer>
                <Table >
                  <TableHeader className='table-detail' style={{backgroundColor: colors.darkBlue, borderRadius: '13px 13px 0 0', padding: isMobile ? '0 0' : "0 30px"}}>
                    <TableRow>
                      <TableHeaderItem color='white' width='85px'>Apto</TableHeaderItem>
                      <TableHeaderItem color='white' width='100px'>Data</TableHeaderItem>
                      <TableHeaderItem color='white' width='400px' mWidth='250px'>Descrição</TableHeaderItem>
                      <TableHeaderItem color='white' width='130px'>
                        Valor
                      </TableHeaderItem>
                    </TableRow>
                  </TableHeader>
                  <TableBody height='170px' padding={isMobile ? '0 0' : "0 30px"} scroll style={{overflowY: 'scroll', height: '40vh', backgroundColor: colors.white, borderRadius: '0 0 13px 13px'}}>
                    {data?.map((itemB, indB) => (
                      <TableRow border key={`row-${indB}`}>
                        <TableBodyItem
                          width='85px'
                          border
                          key={`body-${itemB.Apto}-${indB}`}
                        >
                          <h4 className='mgt-0 mb-8'>{itemB.Apto}</h4>
                        </TableBodyItem>
                        <TableBodyItem
                          width='100px'
                          border
                          key={`body-${itemB.Data}-xx`}
                        >
                          <TextItem className='mgt-0 mb-8'>{itemB.Data}</TextItem>
                        </TableBodyItem>
                        <TableBodyItem
                          width='400px'
                          border
                          key={`body-historico-${indB}-xx`}
                        >
                          <TextItem className='mgt-0 mb-8'>{itemB.Historico}</TextItem>
                        </TableBodyItem>

                        <TableBodyItem
                          width='130px'
                          border
                          weight='500'
                          key={`body-${itemB.Valor}-xx`}
                        >
                          <TextItem
                            weight='500'
                            className='mgt-0 mb-8'
                          >
                            {itemB.Valor ? <span style={{color: isNeg(itemB.Valor) ? colors.red : colors.primary}}>{itemB.Valor}</span> : 'ㅤ'}
                          </TextItem>
                          {itemB.Detalhe && (
                            <span
                              style={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                color: 'blue'
                              }}
                              onClick={() => {
                                setDetailModal(itemB.Detalhe);
                                setModalOpen(true);
                              }}
                            >
                              <TextItem
                                onClick={() => {
                                  isMobile && handleDownloadMobile();
                                }}
                              >
                                Extrato detalhado
                              </TextItem>
                            </span>
                          )}
                        </TableBodyItem>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )
          )}
        </Aligner>
      </FlexItem>
    </>
  );
}

export default Statement;
