import { useNavigate } from 'react-router-dom';
import { DividerHorizontal, LinkComponent, LogoYellowBlue, Text } from '../../styles';
import { colors, fontSize, weight } from '../../theme';
import { SidebarArea, SidebarContainer, SidebarItemsArea } from './styles';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const navigate = useNavigate();
  const items = [
    {
      section: 'Condomínio',
      subitems: [
        { text: 'Emitir 2ª via de boleto', link: '/generate-bill' },
        { text: 'Documentos', link: '/cond-docs' }
      ]
    },
    {
      section: 'Pool',
      subitems: [
        { text: 'Extrato de locação', link: '/statement' },
        { text: 'Documentos', link: '/loc-docs' },
        // { text: 'Cadastro de dependente', link: '/dependent-registration' }
      ]
    }
  ];

  return (
    <>
      <SidebarContainer sidebarOpen={isOpen} onClick={() => toggleSidebar()} />
      <SidebarArea open={isOpen}>
        <LinkComponent to='/'>
          <LogoYellowBlue />
        </LinkComponent>
        <SidebarItemsArea>
          {items.map((item, indIt) => (
            <div key={`li-${indIt}`}>
              <Text
                weight={weight.medium}
                size={fontSize.subtext}
                color={colors.primary}
                key={`item-${indIt}`}
                mb='15px'
              >
                {item.section}
              </Text>
              {item.subitems.map((subItem, indSub) => (
                <LinkComponent
                  color={colors.darkBlue}
                  decoration='none'
                  p='5px'
                  to={subItem.link}
                  key={`sub-${indSub}`}
                >
                  {subItem.text}
                </LinkComponent>
              ))}
              <DividerHorizontal key={`div-${indIt}`} padding='30px 0 0' />
            </div>
          ))}
          <LinkComponent
            color={colors.secondary}
            p='15px 0'
            w='800'
            to={'/news'}
            key={`sub-news-page`}
          >
            ACONTECE NO COSTÃO
          </LinkComponent>
        </SidebarItemsArea>
      </SidebarArea>
    </>
  );
};

export default Sidebar;
