import styled from 'styled-components';
import { colors } from "../../theme";

const ImgRender = styled.img`
  width: ${({ width }) => width || '100px'};
  height: ${({ height }) => height || '100px'};
  cursor: pointer;
  @media (max-width: 578px) {
    width: ${(props) => props.mWidth || props.width};
    height: ${(props) => props.mHeight || props.height};
  }
`;

const ItemsCard = styled.div`
    cursor: ${({ hasCursor }) => hasCursor && 'pointer'};
    display: flex;
    width: 50%;
    height: 100px;
  }
  @media (max-width: 578px) {
    width: 70%;
    height: 85px;
  }
`

const ItemText = styled.div`
  width: ${props => props.width || '100%'};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({bgColor}) => bgColor ? colors[bgColor] : colors.darkBlue};
  border-radius: 0 13px 13px 0;

`

export { ImgRender, ItemsCard, ItemText };
