import { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { Alert } from '../../components';
import UserContext from '../../context/UserContext';
import { ImgRender } from '../../pages/MidLevelPage/styles';
import { FlexItem, Text } from '../../styles';
import { colors, fontSize, weight } from '../../theme';
import Announcements from '../Announcements';
import { AlertSection, ItemsCard, ItemText } from './styles';

const Home = ({ items }) => {
  const name = localStorage.getItem('name');
  const justLogged = localStorage.getItem('just_logged');
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState();
  const [alertMessage, setAlertMessage] = useState('');
  const { state, setState } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
      setState({
        ...state,
        breadcrumb: [
          {
            text: "Home"
          }
        ],
      });
    // eslint-disable-next-line
  }, [items]);

  useEffect(() => {
    if (justLogged) {
      setAlertType('success');
      setAlertMessage('Login efetuado com sucesso');
      setAlertOpen(true);
      setTimeout(() => {
        setAlertOpen(false);
      }, 3000);
      localStorage.removeItem('just_logged');
    }
  }, [justLogged]);

  return (
    <>
      {alertOpen && <Alert message={alertMessage} type={alertType} />}
      <FlexItem tAlign='center'>
        <Text
          size={fontSize.text}
          weight={weight.bold}
          inline
          color={colors.primary}
          mP='0 10px'
        >
          Seja bem vindo(a),{' '}
        </Text>
        <Text
          size={fontSize.text}
          weight={weight.bold}
          inline
          color={colors.primary}
        >
          {name}.
        </Text>
      </FlexItem>
      <AlertSection  mInline={isMobile ? '5%' : '0'}>
        <Announcements />
      </AlertSection>
      <FlexItem
        flex
        mWidth='100%'
        width='100%'
        height='100%'
        minHeight='200px'
        jFlex
        bFlex={'center'}
        gap={!isMobile ? '55px' : '15px'}
      >
        {items.map((item, index) => (
          <FlexItem
            mWidth='auto'
            tAlign='center'
            key={`${item.link}-${index}`}
          >            
            <ItemsCard hasCursor onClick={() => navigate(item.link)} className='expand-border'>
              <ImgRender
                key={`${item.link}-${index}`}
                alt={item.link}
                src={item.src}
                width={isMobile ? '100px' : '165px'}
                height={isMobile ? '100px' : '165px'}
              />            
              <ItemText color='darkBlue' width={isMobile ? '100px' : '165px'}>
                <Text
                  textAlign='center'
                  color={colors.white}
                  size={isMobile ? '9px' : '12px'}
                  mt='5px'
                  mb='5px'
                  hasCursor
                  >
                  {item.text}
                </Text>
              </ItemText>
            </ItemsCard>
          </FlexItem>
        ))}
      </FlexItem>
    </>
  );
};

export default Home;
