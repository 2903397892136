import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  OutlinedInput
} from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ptBR } from '@mui/x-date-pickers/locales';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import UserContext from '../../context/UserContext';
import profissoesList from '../../data/profissoes.json';
import {
  getOwnerByUH,
  getOwnerData,
  updateByUH,
  updateDependent,
  updateOwner
} from '../../services/owner';
import { getUH } from '../../services/uh';
import { Title } from '../../styles';
import { colors } from '../../theme';
import { sortAlphanumeric, validarCPF, validateEmail } from '../../utils/index';

const DependantRegistration = () => {
  
  const { state, setState } = useContext(UserContext);
  const isAdmin = (localStorage.getItem("is_admin") === 'true');
  const [listUH, setListUH] = useState([]);
  const [uh, setUH] = useState(null);
  const [openMessage, setOpenMessage] = useState(false);
  const [openMessageErr, setOpenMessageErr] = useState(false);
  const [proprietario, setProprietario] = useState(null);
  const [emailsAdicionais, setEmailsAdicionais] = useState({
    email: "",
    listaEmails: [],
    msgErro: ""
  });
  const [proprietarioErro, setProprietarioErro] = useState({
    nome: false,
    nascimento: false,
    cpf_cnpj: false,
    profissao_outros: false
  });
  
  const [conjugeErro, setConjugeErro] = useState({
    nome: false,
    nascimento: false,
    cpf_cnpj: false,
    profissao_outros: false
  });
  const [dependentes, setDependentes] = useState([]);
  const [exibirProfissao, setExibirProfissao] = useState(false);
  const errosDependentes = {
    nome: false,
    nascimento: false,
    cpf: false,
    grau_parentesco: false
  };

  useEffect(() => {
      setState({
        ...state,
        breadcrumb: [
          {
            text: "Home", link: '/'
          },{
            text: 'Atualização Cadastral'
          }
        ],
      });
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let id = Number(e.target.id.value) || undefined;

    const person = {
      nome: e.target.nome?.value,
      nascimento: e.target.nasc?.value || null,
      cpf_cnpj: e.target.cpf_cnpj?.value,
      cpf: e.target.cpf_cnpj?.value,
      telefone: e.target.telefone?.value,
      email: e.target.email?.value,
      cep: e.target.cep?.value,
      dt_atu: e.target.dt_atu?.value,
      dt_inc: e.target.dt_inc?.value,
      endereco: e.target.endereco?.value,
      bairro: e.target.bairro?.value,
      numero: e.target.numero?.value,
      municipio: e.target.municipio?.value,
      uf: e.target.uf?.value,
      pais: e.target.pais?.value,
      complemento: e.target.complemento?.value,
      profissao: e.target.profissao?.value,
      profissao_outros: e.target.profissao_outros?.value,
      aniversario_casamento: e.target.aniversario_casamento?.value,
      grau_parentesco: e.target.grau_parentesco?.value,
      nome_banco: e.target.nome_banco?.value,
      cod_banco: e.target.cod_banco?.value,
      conta_corrente: e.target.conta_corrente?.value,
      agencia: e.target.agencia?.value,
    };

    if(e.target.aniversario_casamento?.value.length === 0){
      person.aniversario_casamento = null;
    }

    if(person.cpf_cnpj){
      person.cpf = person.cpf_cnpj = person.cpf_cnpj.replace(/[^0-9]/g, '');
    }

    if(!isAdmin && erroCampos(person)){
      return false;
    }

    if(person.profissao === "Outros"){
      person.profissao = person.profissao_outros;
    }
    
    let propObj = proprietario;
    if(!person.grau_parentesco){
      person.emails = emailsAdicionais.listaEmails;
      
      propObj = {...propObj, ...person};

      if(!isAdmin){
        propObj.nome_banco = proprietario.nome_banco;
        propObj.cod_banco = proprietario.cod_banco;
        propObj.conta_corrente = proprietario.conta_corrente;
        propObj.agencia = proprietario.agencia;
      }
    }else{
      const conjuge = propObj.dependentes.find(x => x.grau_parentesco === "Cônjuge");
      propObj.dependentes = propObj.dependentes.filter(x => x.id !== id);

      if(conjuge){
        propObj.dependentes.push({...conjuge, ...person});
      }else{
        propObj.dependentes.push(person);
      }
    }

    setProprietario(propObj);
    saveOwner(propObj);
  }

  const addFormDependente = () => {
    setDependentes([...dependentes, {
      id: null,
      key: 0,
      ativo: true,
      nome: '',
      cpf: '',
      grau_parentesco: '',
      nascimento: '',
      erros: {
        nome: false,
        nascimento: false,
        cpf: false,
        cpfInvalido: false,
        grau_parentesco: false
      }
    }])
  }

  const erroCampos = (person) => {
    let formError = {
      nome: (person.nome.trim().length === 0),
      nascimento: (person.nascimento == null || person.nascimento.trim().length === 0),
      cpf_cnpj: (person.cpf_cnpj.trim().length === 0)
    };

    if(person.profissao === "Outros"){
      formError.profissao_outros = (person.profissao_outros.trim().length === 0);
    }else{
      formError.profissao_outros = false;
    }

    if(!person.grau_parentesco){
      setProprietarioErro({...formError});
    }else{
      setConjugeErro({...formError});
    }

    let erroCampo = (
      formError.nome ||
      formError.nascimento ||
      formError.cpf_cnpj ||
      formError.profissao_outros
    );

    return erroCampo;
  }

  const erroCamposDependente = (dependente) => {
    dependentes[dependente.key].erros.nome = (dependente.nome.trim().length === 0);
    dependentes[dependente.key].erros.grau_parentesco = (dependente.grau_parentesco.trim().length === 0);
    dependentes[dependente.key].erros.cpf = (dependente.cpf.trim().length === 0);
    dependentes[dependente.key].erros.nascimento = (dependente.nascimento.trim().length === 0 || dependente.nascimento === '01/01/0001');

    if(!dependentes[dependente.key].erros.cpf){
      dependentes[dependente.key].erros.cpfInvalido = !validarCPF(dependente.cpf.replace(/[^0-9]/g, ''));
    }

    setDependentes([...dependentes]);

    let erroCampo = (
      dependentes[dependente.key].erros.nome ||
      dependentes[dependente.key].erros.grau_parentesco ||
      dependentes[dependente.key].erros.cpf ||
      dependentes[dependente.key].erros.cpfInvalido ||
      dependentes[dependente.key].erros.nascimento
    );

    return erroCampo;
  }

  const handleSubmitDependentes = async (key) => {
    if(isAdmin || (!isAdmin && !erroCamposDependente(dependentes[key]))){
      let listDep = proprietario.dependentes.filter((x) => x.id !== dependentes[key].id);

      if(dependentes[key].cpf){
        dependentes[key].cpf = dependentes[key].cpf.replace(/[^0-9]/g, '');
      }else{
        dependentes[key].cpf = null;
      }

      const propObj = {...proprietario, dependentes: [...listDep, dependentes[key]]};
      saveOwner(propObj);
    }
  }

  const saveOwner = async (propObj) => {
    setState({ ...state, loading: true });

    let savePromisse;

    if(uh){
      savePromisse = updateByUH(uh, propObj);
    }else{
      savePromisse = updateOwner(propObj);
    }

    savePromisse.then((value) => {
      setState({ ...state, loading: false });
      if(value.status === 200){
        setOpenMessage(true);
      }else{
        setOpenMessageErr(true);
      }
    });
  };

  const addEmail = () => {
    if(!validateEmail(emailsAdicionais.email)){
      setEmailsAdicionais({...emailsAdicionais, msgErro: "Por favor informe um e-mail válido."});
      return;
    }

    if(!emailsAdicionais.listaEmails.includes(emailsAdicionais.email)){
      emailsAdicionais.listaEmails.push(emailsAdicionais.email);
      setEmailsAdicionais({...emailsAdicionais, listaEmails: emailsAdicionais.listaEmails, msgErro: "" });
    }else{
      setEmailsAdicionais({...emailsAdicionais, msgErro: "E-mail já informado."});
    }
  }

  const removeEmail = (email) => {
    const emailFilter = emailsAdicionais.listaEmails.filter(x => x !== email);
    setEmailsAdicionais({...emailsAdicionais, listaEmails: emailFilter});
  }

  const setOwnerProperties = (data) => {
    setProprietario(data);
    setEmailsAdicionais({...emailsAdicionais, listaEmails: data.emails});

    const listaDependentes = data.dependentes.filter((x) => x.grau_parentesco !== "Cônjuge");
    listaDependentes.forEach(item => {
      item.erros = Object.assign({}, errosDependentes);
    });

    setDependentes(listaDependentes);
  }

  useEffect(() => {
    getUH().then((res) => {
      let uhs = res.data.map(x => {
        return {label: x.descricao, value: x.id};
      });

      setListUH(uhs.sort((a, b) => sortAlphanumeric(a.value, b.value)));
    });

    if(uh){
      getOwnerByUH(uh).then(({data}) => {
        setOwnerProperties(data);
      });
    }else{
      getOwnerData().then(({data}) => {
        setOwnerProperties(data);
      });
    }    
  }, [uh]);

  const FormDependente = ({dependente}) => {
    return (
      <Accordion
        sx={{ margin: '1em 0' }}
        key={`key-${dependente?.key}`}
      >
        <AccordionSummary
          sx={{ padding: '0 1em', fontWeight: 500 }}
          expandIcon={<ExpandMoreIcon />}>
          <Title fontSize={isMobile ? '16px' : '18px'} color={colors.darkGray} padding={0} mb='1em'>
            {dependente.nome !== '' ? dependente.nome : 'Dependente'}
          </Title>

        </AccordionSummary>
        <AccordionDetails>
          
          <Grid container spacing={2}>
            <Grid item xl={8} md={8} xs={12}>
              <FormControl variant="outlined"
                sx={{ width: '100%', marginRight: '1em' }}
                error={dependente.erros.nome}>

                <OutlinedInput placeholder='Nome' defaultValue={dependente.nome}
                  onChange={(event) => {
                    dependentes[dependente.key].nome = event.target.value;
                    setDependentes(dependentes);
                  }}
                  style={{fontSize: isMobile ? '14px' : '1rem'}}
                  />
                {dependente.erros.nome ? <FormHelperText id="component-error-text">Por favor informe o nome.</FormHelperText> : null}
              </FormControl>
            </Grid>

            <Grid item xl={4} md={4} xs={6}>
              <FormControl error={dependente.erros.nascimento} variant="outlined" sx={{ width: '100%'}}>
                <LocalizationProvider dateAdapter={AdapterDayjs} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale="pt-br">
                  <MobileDatePicker
                    defaultValue={dependente.nascimento && dependente.nascimento !== '01/01/0001'  ? dayjs(dependente.nascimento, 'DD/MM/YYYY') : null}
                    slotProps={{actionBar: {actions: ['clear', 'cancel', 'accept']}}}
                    maxDate={dayjs()}
                    onChange={(value) => {
                      dependentes[dependente.key].nascimento = value ? dayjs(value).format('DD/MM/YYYY') : "";
                      setDependentes(dependentes);
                    }}
                    label="Data de nascimento"
                    format="DD/MM/YYYY"/>
                    {dependente.erros.nascimento ? <FormHelperText id="component-error-text">Por favor informe a data de nascimento.</FormHelperText> : null}
                </LocalizationProvider>
              </FormControl>
            </Grid>
            
            <Grid item xl={4} md={4} xs={6}>
              <FormControl error={dependente.erros.cpf || dependente.erros.cpfInvalido}
              variant="outlined" sx={{ width: '100%', marginRight: '1em', fontSize: isMobile ? '14px' : '1rem' }}
              onChange={(event) => {
                dependentes[dependente.key].cpf = event.target.value;
                setDependentes(dependentes);
              }}>
                <OutlinedInput placeholder='CPF' defaultValue={dependente.cpf} style={{fontSize: isMobile ? '14px' : '1rem'}}/>
                {dependente.erros.cpf ? <FormHelperText id="component-error-text">Por favor informe o CPF.</FormHelperText> : null}
                {dependente.erros.cpfInvalido ? <FormHelperText id="component-error-text">CPF inválido, por favor informe o CPF corretamente.</FormHelperText> : null}
              </FormControl>
            </Grid>

            <Grid item xl={6} md={6} xs={8}>
              <FormControl error={dependente.erros.grau_parentesco}
              variant="outlined" sx={{ width: '100%', marginRight: '1em', fontSize: isMobile ? '14px' : '1rem' }}>
                <Select
                  id='grau_parentesco'
                  defaultValue={dependente.grau_parentesco}
                  displayEmpty
                  input={<OutlinedInput />}
                  inputProps={{ 'aria-label': 'Without label' }}
                  onChange={(event) => {
                    dependentes[dependente.key].grau_parentesco = event.target.value;
                    setDependentes(dependentes);
                  }}
                >
                  <MenuItem disabled value="">
                    <em
                      style={{    
                          color: '#adadad',
                          fontStyle: 'inherit',
                          fontWeight: '300'
                    }}>
                      Grau de parentesco
                    </em>
                  </MenuItem>
                  
                  <ListSubheader style={{fontWeight: "bold"}}>Ascendente</ListSubheader>
                  <MenuItem value='Pai e Mãe'>Pai e Mãe</MenuItem>
                  <MenuItem value='Avô e Avó'>Avô e Avó</MenuItem>
                  <MenuItem value='Bisavô e Bisavó'>Bisavô e Bisavó</MenuItem>

                  <ListSubheader style={{fontWeight: "bold"}}>Descendente</ListSubheader>
                  <MenuItem value='Filho e Filha'>Filho e Filha</MenuItem>
                  <MenuItem value='Neto e Neta'>Neto e Neta</MenuItem>
                  <MenuItem value='Bisneto e Bisneta'>Bisneto e Bisneta</MenuItem>
                  <MenuItem value='Irmão e Irmã'>Irmão e Irmã</MenuItem>
                </Select>

                {dependente.erros.grau_parentesco ? <FormHelperText id="component-error-text">Por favor informe o grau de parentesco.</FormHelperText> : null}
              </FormControl>
            </Grid>

            <Grid item xl={2} md={2} xs={4}>
              <FormControl variant="outlined" sx={{ width: '100%' }}>
                  <FormControlLabel
                    control={
                      <Checkbox name="ativo" color='success' defaultChecked={dependente.ativo}
                        onChange={(event) => {
                          dependentes[dependente.key].ativo = event.target.checked;
                          setDependentes(dependentes);
                          
                          if(dependente.id){
                            updateDependent(dependente.id, event.target.checked ? '1' : '0');
                          }
                        }}
                      />
                    }
                    label="Ativo"
                  />
              </FormControl>
            </Grid>
          </Grid>
          
          <Button
              style={{
                marginLeft: 'auto',
                marginTop: '3em',
                right: '0px',
                display: 'flex',
                backgroundColor: colors.gray,
                width: '200px'
              }}
              type='button'
              onClick={() => handleSubmitDependentes(dependente.key)}>
              SALVAR
          </Button>
        </AccordionDetails>
      </Accordion>
    );
  }

  const AddOutlinedInput = (placeholder, id, defaultValue, sx, disabled = false) => (
    <OutlinedInput placeholder={placeholder} id={id} defaultValue={defaultValue} sx={sx} disabled={disabled}/>
  );

  const Form = ({formData, title, defaultExpanded, formError}) => {
    return (
      <Accordion
        defaultExpanded={defaultExpanded}
        sx={{ margin: '1em 0' }}>
        <AccordionSummary
          sx={{ padding: '0 1em', fontWeight: 500 }}
          expandIcon={<ExpandMoreIcon />}>
          <Title fontSize={isMobile ? '16px' : '18px'} color={colors.darkGray} padding={0} mb='1em'>
            {title}
          </Title>
        </AccordionSummary>
        <AccordionDetails>
        <div>
          <form onSubmit={handleSubmit}>
            <input id='id' defaultValue={formData?.id} style={{ visibility: 'hidden' }}/>
            <Grid container spacing={2}>
              <Grid item xl={8} md={8} xs={12}>
                <FormControl variant="outlined"
                    sx={{ width: '100%', marginRight: '1em', fontSize: '12px' }}
                    error={formError.nome} >
                  {AddOutlinedInput('Nome', 'nome', formData?.nome, {fontSize: isMobile ? '14px' : '1rem'}, (title === 'Proprietário'))}
                  {formError.nome ? <FormHelperText id="component-error-text">Por favor informe o nome.</FormHelperText> : null}
                </FormControl>
              </Grid>
             
              <Grid item xl={4} md={4} xs={12}>
                <FormControl error={formError.nascimento} variant="outlined" sx={{ width: '100%'}}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale="pt-br">
                    <MobileDatePicker 
                    slotProps={{textField: {id: 'nasc'}, actionBar: {actions: ['clear', 'cancel', 'accept']}}}
                      maxDate={dayjs()}
                      label="Data de nascimento"
                      format="DD/MM/YYYY"
                      style={{fontSize: isMobile ? '14px' : '1rem'}}
                      defaultValue={ formData && formData.nascimento ? dayjs(formData.nascimento, 'DD/MM/YYYY') : null}/>
                      {formError.nascimento ? <FormHelperText id="component-error-text">Por favor informe a data de nascimento.</FormHelperText> : null}
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xl={4} md={4} xs={6}>
                <FormControl error={formError.cpf_cnpj} variant="outlined" sx={{ width: '100%', marginRight: '1em' }}>
                  {AddOutlinedInput('CPF/CNPJ', 'cpf_cnpj', (formData && formData.cpf_cnpj ? formData.cpf_cnpj : formData?.cpf), {fontSize: isMobile ? '14px' : '1rem'}, (title === 'Proprietário'))}
                  {formError.cpf_cnpj ? <FormHelperText id="component-error-text">Por favor informe o CPF/CNPJ.</FormHelperText> : null}
                </FormControl>
              </Grid>

              <Grid item xl={4} md={4} xs={6}>
                <FormControl variant="outlined" sx={{ width: '100%', marginRight: '1em' }}>
                  {AddOutlinedInput('Telefone (WhatsApp)', 'telefone', formData?.telefone, {fontSize: isMobile ? '14px' : '1rem'})}
                </FormControl>
              </Grid>

              <Grid item xl={4} md={4} xs={12}>
                <FormControl variant="outlined" sx={{ width: '100%'}}>
                  {AddOutlinedInput('Email', 'email', formData?.email, {fontSize: isMobile ? '14px' : '1rem'})}
                </FormControl>
              </Grid>

              <Grid item xl={2} md={2} xs={12}>{AddOutlinedInput('CEP', 'cep', formData?.cep, { width: '100%', marginRight: '1em', fontSize: isMobile ? '14px' : '1rem' })}</Grid>              
              <Grid item xl={4} md={4} xs={12}>{AddOutlinedInput('Endereço', 'endereco', formData?.endereco, {width: '100%', marginRight: '1em',fontSize: isMobile ? '14px' : '1rem'})}</Grid>              
              <Grid item xl={4} md={4} xs={9}>{AddOutlinedInput('Bairro', 'bairro', formData?.bairro, {width: '100%', marginRight: '1em',fontSize: isMobile ? '14px' : '1rem'})}</Grid>              
              <Grid item xl={2} md={2} xs={3}>{AddOutlinedInput('Nro', 'numero', formData?.numero, {width: '100%'})}</Grid>

              <Grid item xl={4} md={4} xs={9}>{AddOutlinedInput('Cidade', 'municipio', formData?.municipio, {width: '100%', marginRight: '1em',fontSize: isMobile ? '14px' : '1rem'})}</Grid>
              <Grid item xl={1} md={1} xs={3}>{AddOutlinedInput('UF', 'uf', formData?.uf, {width: '100%', marginRight: '1em',fontSize: isMobile ? '14px' : '1rem'})}</Grid>
              <Grid item xl={3} md={3} xs={3}>{AddOutlinedInput('País', 'pais', formData?.pais, {width: '100%', marginRight: '1em',fontSize: isMobile ? '14px' : '1rem'})}</Grid>
              <Grid item xl={4} md={4} xs={9}>{AddOutlinedInput('Complemento', 'complemento', formData?.complemento, {width: '100%', fontSize: isMobile ? '14px' : '1rem'})}</Grid>

              
              <Grid item xl={6} md={6} xs={6}>
                <Autocomplete
                  id="profissao"
                  defaultValue={formData?.profissao}
                  options={profissoesList.sort()}
                  sx={{ width: '100%', marginRight: '1em', fontSize: isMobile ? '14px' : '1rem' }}
                  renderInput={(params) => <TextField {...params} placeholder="Profissão" />}
                  onChange={(_, newValue) => {
                    setExibirProfissao((newValue === "Outros"));
                  }}
                />
              </Grid>

              {title !== 'Cônjuge' ?
              <Grid item xl={6} md={6} xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale="pt-br">
                  <MobileDatePicker sx={{ width: '100%', fontSize: isMobile ? '14px' : '1rem' }} 
                  slotProps={{textField: {id: 'aniversario_casamento'}, actionBar: {actions: ['clear', 'cancel', 'accept']}}}
                  maxDate={dayjs()}
                  label="Aniversário de casamento" format="DD/MM/YYYY"
                  defaultValue={formData && formData.aniversario_casamento ? dayjs(formData.aniversario_casamento, 'DD/MM/YYYY') : null}/>
                </LocalizationProvider>
              </Grid>: <input id='grau_parentesco' defaultValue="Cônjuge" style={{ visibility: 'hidden', fontSize: isMobile ? '14px' : '1rem' }}/>}
            
              {exibirProfissao &&
              <Grid item xl={12} md={12} xs={12}>
                <FormControl error={formError.profissao_outros} variant="outlined" sx={{ width: '100%', marginRight: '1em', fontSize: isMobile ? '14px' : '1rem' }}>
                  {AddOutlinedInput('Digite a Profissão', 'profissao_outros', formData?.profissao)}
                  {formError.profissao_outros ? <FormHelperText id="component-error-text">Por favor digite a profissão.</FormHelperText> : null}
                </FormControl></Grid>}  

              {title !== 'Cônjuge' &&
              <>
                <Grid item xl={10} md={10} xs={8}>
                  <FormControl error={emailsAdicionais.msgErro.length > 0} variant="outlined" sx={{ width: '100%', marginRight: '1em', fontSize: isMobile ? '14px' : '1rem' }}>
                    <OutlinedInput placeholder='Emails adicionais' id="emails_adicionais" style={{fontSize: isMobile ? '14px' : '1rem'}}
                      onChange={event => {
                        emailsAdicionais.email = event.target.value;
                        setEmailsAdicionais(emailsAdicionais);
                      }}/>
                  </FormControl>
                </Grid>
                <Grid item xl={2} md={2} xs={2}>
                  <Button color="primary" variant="outlined" style={{width: '100%', height: '100%', fontSize: isMobile ? '14px' : '1rem'}} onClick={() => addEmail()}>
                    <AddIcon color="primary"/>
                  </Button>
                </Grid>
                <Grid item xl={12} md={12} xs={12} style={{paddingTop: '0px', marginLeft: '14px'}}>
                  {(emailsAdicionais.msgErro.length > 0) && <FormHelperText id="component-error-text" style={{color: "#d32f2f"}}>{emailsAdicionais.msgErro}</FormHelperText>}
                </Grid>
                <Grid item xl={12} md={12} xs={12}>
                  {emailsAdicionais.listaEmails.map(x => <Chip key={x} label={x} variant="outlined" style={{marginRight: '5px', marginBottom: '5px', fontSize: isMobile ? '14px' : '1rem'}} onDelete={() => removeEmail(x)}/>)}
                </Grid>
              </>
              }

                {isAdmin && title === 'Proprietário' && 
                  <>
                    <Grid item xl={6} md={6} xs={6}>{AddOutlinedInput('Nome do banco', 'nome_banco', formData?.nome_banco, {width: '100%', fontSize: isMobile ? '14px' : '1rem'})}</Grid>
                    <Grid item xl={6} md={6} xs={6}>{AddOutlinedInput('Código do banco', 'cod_banco', formData?.cod_banco, {width: '100%', fontSize: isMobile ? '14px' : '1rem'})}</Grid>
                    <Grid item xl={6} md={6} xs={6}>{AddOutlinedInput('Conta corrente', 'conta_corrente', formData?.conta_corrente, {width: '100%', fontSize: isMobile ? '14px' : '1rem'})}</Grid>
                    <Grid item xl={6} md={6} xs={6}>{AddOutlinedInput('Agência', 'agencia', formData?.agencia, {width: '100%', fontSize: isMobile ? '14px' : '1rem'})}</Grid>
                  </>
                }
            </Grid>
            
            <Button
              style={{
                marginLeft: 'auto',
                marginTop: '3em',
                right: '0px',
                display: 'flex',
                backgroundColor: colors.gray,
                width: '200px'
              }}
              type='submit'>
              SALVAR
            </Button>
          </form>
        </div>
        </AccordionDetails>
      </Accordion>
    );
  }

  if (!proprietario) return null;
  return (
    <div style={{marginBottom: '20em', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <Typography variant="subtitle1" display="block" style={{width: '85vw', marginBottom: '10px', textAlign: 'justify', color: colors.darkBlue, paddingInline:'1em'}}>
        <p>Tendo em vista que as informações cadastrais de nossos Proprietários devem ser mantidas atualizadas com o objetivo de que 
        seja fornecido o melhor e mais personalizado atendimento, solicitamos o preenchimento dos dados abaixo.</p>

        <p>
          Maiores informações podem ser obtidas pelo e-mail <a href="mailto:proprietarios@costao.com.br">proprietarios@costao.com.br</a>, <a href="mailto:adalberto@costao.com.br">adalberto@costao.com.br</a> ou telefone <a href="tel:048-3261 1850">048-3261 1850</a> com Ana Lucia Santos (Coordenadora da recepção) ou Thiago Souza (Subcoordenador da recepção).
        </p>
      </Typography>

      {isAdmin && 
      <Autocomplete
        options={listUH}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Unidade habitacional" />}
        onChange={(_, newValue) => setUH(newValue?.value)}
        style={{backgroundColor: 'White'}}
      />}

      <Form formData={proprietario} title="Proprietário" defaultExpanded={true} formError={proprietarioErro} />
      <Form formData={proprietario.dependentes.find(x => x.grau_parentesco === "Cônjuge")} title="Cônjuge" defaultExpanded={false} formError={conjugeErro} />

      <Title fontSize='18px' color={colors.darkGray} padding={0} mb='1em'>
        Dependentes
      </Title>
      {
        dependentes.map((d, i) => {
          d.key = i; 
          return (<FormDependente key={i} dependente={d}/>);
        })
      }
      <div style={{marginBlock: '3em'}}>
      <Button onClick={addFormDependente} style={{ marginBottom: '3em', color: 'white', backgroundColor: colors.darkBlue }}>
        Adicionar dependente
      </Button>  
      </div>

      <Snackbar open={openMessage} autoHideDuration={15000} 
        onClose={() => {setOpenMessage(false);}} 
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Alert severity="success">
          <AlertTitle>Dados salvos com sucesso</AlertTitle>
          Agradecemos por estarmos juntos e estamos sempre a disposição para gerar experiências inesquecíveis para você e sua família!
        </Alert>
      </Snackbar>

      <Snackbar open={openMessageErr} autoHideDuration={15000} 
        onClose={() => {setOpenMessageErr(false);}}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Alert severity="error">
          <AlertTitle>Erro ao salvar</AlertTitle>
          Ocorreu um erro ao salvar, tente novamente mais tarde!
        </Alert>
      </Snackbar>
      
    </div>
  );
}

export default DependantRegistration;
