import styled from "styled-components";
import { colors } from "../../theme";

const AlertSection = styled.section`
  height: 40vh;
  margin-inline: ${(props) => props.mInline};
  background: ${colors.white};
  margin-top: 1em;
  overflow-x: auto;
  box-shadow: 0px 2px 4px 0px #00000040;
  border-radius: 8px;
  @media (max-width: 500px) {
    margin-top: .5em;
    height: 300px;
  }
`;

const ItemsCard = styled.div`
    cursor: ${({ hasCursor }) => hasCursor && 'pointer'};
    transition: 200ms;
    &:hover {
      opacity: 0.85;
      background-color: ;
      transition: 200ms;
    }
`

const ItemText = styled.div`
  width: ${props => props.width};
  background-color: ${colors.darkBlue};
  margin-top: -20px;
  position: absolute;
  border-radius: 0 0 13px 13px;
  @media (max-width: 500px) {
    margin-top: -10px;
  }
`

export { AlertSection, ItemsCard, ItemText };
