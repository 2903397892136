import styled from "styled-components";
import { colors, fontSize } from "../../theme";

const SelectInput = styled.select`
  background: transparent;
  background-color: ${({bgColor}) => bgColor || 'transparent'};
  border: none;
  box-shadow: 0 8px 0 ${(props) => 'lightgray'};
  width: ${({ width }) => width || "100px"};
  color: ${(props) => props.color || colors.darkBlue};
  font-size: ${fontSize.text};
  height: ${(props) => props.height || "48px"};
  display: ${(props) => (props.isFlex ? "flex" : "")};
  border-radius: ${({bRadius}) => bRadius};
  &:focus {
    outline: none;
  }
  margin-top: 5px;
  @media(max-width: 500px){
    height: ${(props)=> props.mHeight}
  }
`;

const OptionsInput = styled.option``;

export { OptionsInput, SelectInput };

