import { useContext, useEffect, useState } from "react";
import { api } from "../../services/api";
import { colors, weight } from "../../theme";

import { isMobile } from "react-device-detect";
import UserContext from "../../context/UserContext";
import { fetchFile } from "../../services/download-file";
import {
  Aligner,
  FlexItem,
  LinkComponent,
  SectionTitle,
  Table,
  TableBody,
  TableBodyItem,
  TableHeader,
  TableHeaderItem,
  TableRow
} from "../../styles";

function Documents({ type }) {
  const [data, setData] = useState(null);
  const token = localStorage.getItem("token");
  const { state, setState } = useContext(UserContext);

  const downloadFile = async (fileId, fileName, tokenI) => {
    setState({ ...state, loading: true });
    await fetchFile("documento", fileId, fileName, tokenI);
    setState({ ...state, loading: false });
  };

  const handleTitle = (type) =>
    ({ C: "do Condomínio", L: "de Pool" }[type] || "");

  const fetchDocs = (type, token) => {
    api
      .get(`/documento/${type}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(res.data);
      });
  };

  useEffect(() => {
    fetchDocs(type, token);
    if (type === "C") {
      setState({
        ...state,
        breadcrumb: [
          {
            text: "Home",
            link: "/",
          },
          { text: "Condomínio", link: "/cond" },
          { text: "Documentos do Condomínio" },
        ],
      });
    } else {
      setState({
        ...state,
        breadcrumb: [
          {
            text: "Home",
            link: "/",
          },
          { text: "Pool", link: "/loc" },
          { text: "Documentos de Pool" },
        ],
      });
    }
  }, [type, token]);

  return (
    <>
      <FlexItem margin="auto" isFlex width="800px">
        <Aligner direction="center">
          <SectionTitle>{`Documentos ${handleTitle(type)}`}</SectionTitle>
          {!data ? (
            <SectionTitle
              mt="40px"
              weight={weight.light}
              color={colors.primary}
            >
              Não existem documentos para serem mostrados.
            </SectionTitle>
          ) : (
            <Table>
              <TableHeader className='table-detail' style={{backgroundColor: colors.darkBlue, borderRadius: '13px 13px 0 0', padding: isMobile ? '0 0' : "0 30px"}}>
                <TableRow>
                  <TableHeaderItem color='white' mWidth="100px">Data</TableHeaderItem>
                  <TableHeaderItem color='white' width='600px'>Descrição</TableHeaderItem>
                  <TableHeaderItem color='white'></TableHeaderItem>
                </TableRow>
              </TableHeader>
              <TableBody height='170px' padding={isMobile ? '0 0' : "0 30px"} scroll style={{overflowY: 'scroll', height: '40vh', backgroundColor: colors.white, borderRadius: '0 0 13px 13px'}}>
                {data.map((itemB, indB) => (
                  <TableRow border key={`row-${indB}`}>
                    <TableBodyItem
                      width="100px"
                      border
                      key={`body-${itemB.data}-m`}
                    >
                      {itemB.data}
                    </TableBodyItem>
                    <TableBodyItem
                      width="800px"
                      border
                      key={`body-${itemB.nome}-m`}
                    >
                      {itemB.nome}
                    </TableBodyItem>
                    <TableBodyItem border key={`body-${itemB.id}-m`} pr="10px">
                      <LinkComponent
                        onClick={() =>
                          token && downloadFile(itemB.id, itemB.arquivo, token)
                        }
                        hasCursor
                        color={colors.primary}
                        pt="0"
                        flex
                        decoration='none'
                      >
                        Visualizar
                      </LinkComponent>
                    </TableBodyItem>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Aligner>
      </FlexItem>
    </>
  );
}

export default Documents;
